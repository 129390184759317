.menu-container {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.dropdown-title {
  display: flex;
  width: 100%;
  height: 100%;

  span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-self: center;
  }
}

.download-menu {
  display: flex;
  justify-content: center;
}

.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  perspective: 1000px;
  z-index: 100;
  width: 270px;
  height: 60px;
  border-radius: 60px;
  background-color: #384147;
  color: #ffffff;
  border: 2px solid rgb(56, 65, 71);
  font-weight: 700;
}

.dropdown:hover {
  background: #ffffff;
  color: black;
  border: 2px solid rgb(56, 65, 71);
  cursor: pointer;
}

.dropdown:hover .dropdown_menu li {
  display: block;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  perspective: 1000px;
  z-index: -1;
}

.dropdown_menu li {
  display: none;
  padding: 5px 10px;
  opacity: 0;
}

.dropdown:hover {
  .dropdown_menu {
    border-radius: 10px;
    border: 2px solid #3A4146;
  }
}

.dropdown:hover .dropdown_menu--animated {
  display: block;
}

.dropdown_menu--animated {
  display: none;
}

.dropdown_menu--animated li {
  display: block;
  opacity: 1;

}

.extension-dropdown-button {
  display: flex;
  padding: 10px;

  &:hover {
    background-color: #f2f2f2;
  }

  div {
    flex-grow: 1;
  }

  .download-circle-section-style {
    justify-content: center;
    display: flex;
    flex: 1;
  }

  .addFileExtensions-style {
    font-size: 16px;
    color: #000000;
    flex: 1;
  }

  .fileSize-style {
    color: #4B5257;
    font-size: 14px;
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}

.dropdown_menu-2 .dropdown_item-0 {
  transform-origin: top center;
  animation: rotateX 300ms 60ms ease-in-out forwards;
}

.dropdown_item--disable {
  pointer-events: none;
  .extension-dropdown-button {
    background-color: #f2f2f2;
    opacity: 0.5;
  }
}

.dropdown_menu--true {
  background-color: #ffffff;
}

.dropdown_menu-2 .dropdown_item-1 {
  transform-origin: top center;
  animation: rotateX 300ms 120ms ease-in-out forwards;
}

@-moz-keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@-o-keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@-moz-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@-webkit-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@-o-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@-moz-keyframes downOut {
  0% {
    transform: translateZ(200px) transLateY(40px);
  }
  80% {
    transform: translateZ(-10px) transLateY(0px);
  }
  100% {
    transform: translateZ(0px) transLateY(0px);
  }
}

@-webkit-keyframes downOut {
  0% {
    transform: translateZ(200px) transLateY(40px);
  }
  80% {
    transform: translateZ(-10px) transLateY(0px);
  }
  100% {
    transform: translateZ(0px) transLateY(0px);
  }
}

@-o-keyframes downOut {
  0% {
    transform: translateZ(200px) transLateY(40px);
  }
  80% {
    transform: translateZ(-10px) transLateY(0px);
  }
  100% {
    transform: translateZ(0px) transLateY(0px);
  }
}

@keyframes downOut {
  0% {
    transform: translateZ(200px) transLateY(40px);
  }
  80% {
    transform: translateZ(-10px) transLateY(0px);
  }
  100% {
    transform: translateZ(0px) transLateY(0px);
  }
}

@-moz-keyframes growOut {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes growOut {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-o-keyframes growOut {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes growOut {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-moz-keyframes rotateY {
  0% {
    transform: rotateY(90deg);
  }
  80% {
    transform: rotateY(-10deg);
  }
  100% {
    transform: rotateY(0);
  }
}

@-webkit-keyframes rotateY {
  0% {
    transform: rotateY(90deg);
  }
  80% {
    transform: rotateY(-10deg);
  }
  100% {
    transform: rotateY(0);
  }
}

@-o-keyframes rotateY {
  0% {
    transform: rotateY(90deg);
  }
  80% {
    transform: rotateY(-10deg);
  }
  100% {
    transform: rotateY(0);
  }
}

@keyframes rotateY {
  0% {
    transform: rotateY(90deg);
  }
  80% {
    transform: rotateY(-10deg);
  }
  100% {
    transform: rotateY(0);
  }
}

@-moz-keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@-webkit-keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@-o-keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@-moz-keyframes rotateZ {
  0% {
    opacity: 0;
    transform: translateZ(290px);
  }
  80% {
    transform: translateZ(10px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@-webkit-keyframes rotateZ {
  0% {
    opacity: 0;
    transform: translateZ(290px);
  }
  80% {
    transform: translateZ(10px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@-o-keyframes rotateZ {
  0% {
    opacity: 0;
    transform: translateZ(290px);
  }
  80% {
    transform: translateZ(10px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes rotateZ {
  0% {
    opacity: 0;
    transform: translateZ(290px);
  }
  80% {
    transform: translateZ(10px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@-moz-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-o-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
