@import '../../colors.scss';

.ovHeader {
  width: 80%;
  display: flex;
  justify-content: space-between;

  .ovHCol {
    display: flex;

    .numbers {
      font-size: 30px;
      font-weight: 900;
    }

    .text {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.overview {
  display: flex;
  flex-direction: column;

  .bSection {
    padding: 10px 5px 10px 60px;
  }

  .bHeader {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: 40px;

    .btns {
      margin-left: 25px;
      display: flex;
      justify-content: space-between;
      width: 70px;
      filter: $arrowShadow;
    }
  }

  .smServContainer::-webkit-scrollbar,
  .bgResContainer::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  .bgResContainer {
    display: flex;
    padding: 20px 10px;
    scrollbar-width: none;
    filter: $dropShadow;
    overflow-x: scroll;
  }

  .smServContainer {
    overflow-x: auto;
    width: 100%;
    display: flex;
    padding: 30px 10px;
  }

  .empty {
    color: $menu-bgk-color;
    height: 100px;
    display: flex;
    padding-left: 100px;
    justify-content: center;
    align-items: center;
  }
}
