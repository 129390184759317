@import '../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';
@import './colors.scss';

.table {
  margin: 10px;
  padding: 0 3px;
  filter: drop-shadow(0 0 10px rgba(12, 41, 48, 0.2));
}

.ag-grid {
  .ag-root-wrapper {
    border: none !important;
    border-radius: 5px;
  }

  .date {
    font-size: 12px;
    color: $grey;
  }

  .cursor {
    cursor: pointer;
  }

  .bold {
    font-weight: 500;
  }
}
