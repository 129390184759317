@import '../../../colors.scss';

.summary {
  overflow-y: hidden;
  color: $menu-bgk-color;
  height: 100%;
  padding: 20px;
  display: grid;
  grid-template-rows: 91% auto;
  row-gap: 3%;

  .link {
    color: $active-blue;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .summaryBody {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .row {
      padding: 15px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        display: flex;
        align-items: center;
      }
    }

    .text {
      padding-left: 20px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .launched {
    height: 100%;
    display: grid;
    justify-content: center;
    grid-template-rows: 50% auto;
    align-items: flex-end;

    .infoHeader {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .launchedHeader {
        font-size: 30px;
        font-weight: 800;
      }
      .subHeader {
        padding-top: 15px;
        font-weight: 600;
      }
    }

    .newAnalysis {
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .time-info {
        display: flex;
        align-items: center;

        .time {
          padding-left: 15px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .launchNewBtn {
    height: 40px;
    width: 200px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
  }

  .errorLaunched {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}
