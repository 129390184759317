/// Common variables
$btn-color: #3562ff;
$txt-color: #000000;
$error-color: #ff0000;
$grey: #9d9db0;

/// Login Page
$input-bgk-color: #f5f6f8;
$forgot-password-color: #7c7c7c;

/// Menu
$menu-bgk-color: #384147;
$active-blue: #3562ff;
$active-white: #ffffff;

// tiles
$tile-hover: #ff764d;

// Analysis Wizzard
$done-wizzard: #bdff00;

$dropShadow: drop-shadow(0 0 7px rgba(12, 41, 48, 0.2));
$arrowShadow: drop-shadow(0 0 5px rgba(12, 41, 48, 0.5));
