@import '../../colors.scss';

.analysis {
  .header {
    background-color: $active-blue;
    border-bottom-right-radius: 200px;

    display: grid;
    grid-template-rows: 30% 70%;
    align-items: center;
    justify-content: center;

    .wizzard-bar {
      display: flex;
      justify-content: center;

      .option {
        height: 30px;
        border-radius: 30px;
        border: 1px solid $active-white;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 13px;
        color: $active-white;
        background-color: $active-blue;
      }

      .active {
        color: $active-blue;
        background-color: $active-white;
      }

      .done {
        color: $menu-bgk-color;
        background-color: $done-wizzard;
      }

      .dot {
        border-top: 5px dotted $active-white;
        width: 30px;
        align-self: center;
        margin: 0 10px;
      }
    }

    .header-description {
      color: $active-white;
      text-align: center;

      .header {
        text-transform: uppercase;
        font-weight: 500;
      }

      .title {
        text-transform: uppercase;
        font-size: 70px;
        font-weight: 900;
      }

      .description {
        margin-top: 20px;
        font-weight: 500;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .backward {
      font-size: 15px;
      font-weight: 600;
      width: 120px;
      height: 40px;
      background-color: $active-white;
      color: $menu-bgk-color;
      border: 2px solid $menu-bgk-color;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
    }

    .forward {
      font-weight: 600;
      text-transform: uppercase;
      height: 40px;
      padding: 5px 20px;
      color: $active-white;
    }

    .disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .border {
    background-color: $active-white;
    border-radius: 5px;
    padding: 20px 20px 10px;
    filter: drop-shadow(0 0 10px rgba(12, 41, 48, 0.2));
  }

  .v-title {
    color: $menu-bgk-color;
    font-size: 25px;
    font-weight: 700;
  }

  .validation {
    label {
      font-size: 14px;
      color: $menu-bgk-color;
    }

    .v-header {
      height: 22%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .v-file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
      }
    }

    .v-body {
      margin-top: 20px;
      height: 66%;
    }

    .v-footer {
      margin-top: 20px;
      height: 5%;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
