$grid-columns: 12;
$grid-gap: 10px;

.grid {
  min-width: 100% !important;
  max-width: 100%;
  display: grid !important;
  grid-gap: $grid-gap;
  grid-template-columns: repeat($grid-columns, 1fr);
}

@for $i from 1 through $grid-columns {
  .col-start-#{$i} {
    grid-column-start: ($i);
  }
  .col-end-#{$i} {
    grid-column-end: ($i + 1);
  }
}

@for $i from 1 through $grid-columns {
  .col-#{$i} {
    grid-column: span $i;
  }
}
